import { GreyCool_10 } from "@czechtvPackagesOverrides/styles";
import { Body18Medium } from "./../fromCTV/index";

export const styles = {
  html: {
    overflowX: "hidden",
  },
  body: {
    ...Body18Medium,
    overflowX: "hidden",
    scrollBehavior: "smooth",
    background: GreyCool_10,
  },
};
