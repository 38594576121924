import {
  mediaMinTablet768,
} from '@czechtvPackagesOverrides/styles/fromCTV/index';

export const styles = {
  img: {
    display: 'block',
  },
  [mediaMinTablet768]: {
    img: {
      display: 'block',
    }
  }
}
