import { GreyCool_10, GreyCool_100 } from '@czechtvPackagesOverrides/styles'
import {
  Body16Small,
  Label16Medium,
  DefaultRadius,
  Blue_base,
} from '@czechtvPackagesOverrides/styles/fromCTV/index';
import { GRID_GAP } from '@consts/consts';

export const styles = {
  table: {
    minWidth: `100%`,
    padding: `${GRID_GAP * 1}px`,
    backgroundColor: '#FFFFFF',
    borderRadius: `${DefaultRadius * 4}px`,
    borderSpacing: '0px',
    '& td, & th': {
      padding: `${GRID_GAP * 1}px ${GRID_GAP * 1}px ${GRID_GAP * 1}px ${GRID_GAP * 1}px `,
      verticalAlign: 'middle',
      borderRight: `1px solid rgba(219, 222, 230, 0.50)`,
      maxWidth: '120px',
      color: GreyCool_100,
    },
    '& th': {
      ...Label16Medium,
      textAlign: 'left',
      textTransform: 'normal !important',
      '& > *': {
        ...Label16Medium,
      }
    },
    '& td': {
      ...Body16Small,
      '& > *': {
        ...Label16Medium,
      }
    },
    '& td:last-child, & th:last-child': {
      borderRight: `none`,
      borderTopRightRadius: `${DefaultRadius * 2}px`,
      borderBottomRightRadius: `${DefaultRadius * 2}px`,
      paddingRight: '12px',
    },
    '& td:first-child, & th:first-child': {
      paddingLeft: '12px',
      borderTopLeftRadius: `${DefaultRadius * 2}px`,
      borderBottomLeftRadius: `${DefaultRadius * 2}px`,
    },
    '& tr:nth-child(odd) td': {
      backgroundColor: `${GreyCool_10}`,
    },
  },
  caption: {
      ...Label16Medium,
      color: Blue_base,
      textAlign: 'left',
      fontWeight: 'bold',
      textTransform: 'normal !important',
      marginBottom: `${DefaultRadius * 4}px`,
  },
}
