import React, { useState, ComponentType, useEffect } from 'react';
import { AnalyticsClient, AnalyticsSubscriber } from '@czechtv/analytics';

export type AnalyticsContextInterface<InnerAnalyticsContext extends Record<string, any> = any> =
  | AnalyticsClient<InnerAnalyticsContext>
  | undefined;

const AnalyticsContext = React.createContext<AnalyticsContextInterface>(undefined);

interface Props {
  children: React.ReactNode;
  subscribers?: AnalyticsSubscriber[];
}

const AnalyticsContextProvider = ({ children, subscribers }: Props) => {
  const [analytics] = useState<AnalyticsClient>(() => new AnalyticsClient(subscribers));

  analytics.setSubscribers(subscribers || []);

  useEffect(() => {
    return () => {
      analytics.destroy();
    };
  }, [analytics]);

  return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>;
};

// interne je v kontextu klient nebo undefined, osetrujeme to pres errory
interface AnalyticsContextPropsInternal {
  analyticsContext: AnalyticsContextInterface;
}

// ven do kontextu nam uz leze klient bez undefined
export interface AnalyticsContextProps {
  analyticsContext: AnalyticsClient;
}

type ComponentWithAnalyticsContextProps<P> = Omit<P, keyof AnalyticsContextPropsInternal>;

// HOC s analytickym kontextem pro pouziti v classscomponente Player
const withAnalyticsContext = <P,>(
  WrappedComponent: ComponentType<P>
): ComponentType<ComponentWithAnalyticsContextProps<P>> => {
  return (props: ComponentWithAnalyticsContextProps<P>) => {
    return (
      <AnalyticsContext.Consumer>
        {(analyticsContext) => {
          if (analyticsContext === undefined) {
            throw new Error('analyticsContext must be used within a AnalyticsContextProvider');
          }
          return <WrappedComponent {...(props as P)} analyticsContext={analyticsContext} />;
        }}
      </AnalyticsContext.Consumer>
    );
  };
};

export { AnalyticsContextProvider, AnalyticsContext, withAnalyticsContext };
