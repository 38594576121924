import { PlayerEvents, PlayerAdContext } from '../../PlayerEvent';

interface GTMPlayerAdEvent {
  interaction: boolean;
  'player.ad': {
    id: string;
    position: number;
    skipAfter: number;
    slot: 'preroll' | 'postroll' | 'midroll';
    time: {
      length: number;
      position: number;
    };
    title: string;
    type: 'ad' | 'self-promo' | 'labeling';
  };
  'player.content.id': string;
  'player.version': string;
}

export const getGTMPlayerAdBaseEvent = (
  context: PlayerAdContext,
  eventName: PlayerEvents['type']
): GTMPlayerAdEvent => {
  return {
    'player.ad': {
      id: context.ad.id,
      position: context.ad.position,
      skipAfter: context.ad.skipAfter,
      slot: context.ad.slot,
      title: context.ad.title,
      type: context.ad.type,
      time: {
        length: Math.floor(context.ad.timeLength),
        position: Math.floor(context.ad.timePosition),
      },
    },
    'player.content.id': context.mainContentId,
    'player.version': context.version,
    interaction: ['PlayerAdSkip', 'PlayerAdClick'].includes(eventName),
  };
};
