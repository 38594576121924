import React, { createContext, RefObject, useContext } from 'react';

export interface ResponsiveContextValues {
  ref?: RefObject<HTMLElement>;
  wasHydrated?: boolean;
}

export const ResponsiveContext = createContext<ResponsiveContextValues>({});

export const useResponsiveContext = () => useContext(ResponsiveContext);

export const ResponsiveContextProvider = ({
  children,
  value,
}: React.PropsWithChildren<{ value: ResponsiveContextValues }>) => {
  return (
    <ResponsiveContext.Provider value={{ ...useResponsiveContext(), ...value }}>
      {children}
    </ResponsiveContext.Provider>
  );
};
