import {
  Hero60XLarge, Hero48Large, Hero36Medium,
  Header24Large, Header21Medium, Header18Small, Header16XSmall,
  IMPORTANT_BOLD, IMPORTANT_MEDIUM, IMPORTANT_REGULAR, DEFAULT_SEMIBOLD
} from '@czechtvPackagesOverrides/styles/fromCTV/index';

/**
 *  Hero
 *  */
export const Hero60XLargeBold = {
  ...Hero60XLarge,
  fontFamily: IMPORTANT_BOLD,
}

export const Hero60XLargeMedium = {
  ...Hero60XLarge,
  fontFamily: IMPORTANT_MEDIUM,
}

export const Hero60XLargeRegular = {
  ...Hero60XLarge,
  fontFamily: IMPORTANT_REGULAR,
}

export const Hero48LargeBold = {
  ...Hero48Large,
  fontFamily: IMPORTANT_BOLD,
}

export const Hero48LargeMedium = {
  ...Hero48Large,
  fontFamily: IMPORTANT_MEDIUM,
}

export const Hero48LargeRegular = {
  ...Hero48Large,
  fontFamily: IMPORTANT_REGULAR,
}

export const Hero36MediumBold = {
  ...Hero36Medium,
  fontFamily: IMPORTANT_BOLD,
}

export const Hero36MediumMedium = {
  ...Hero36Medium,
  fontFamily: IMPORTANT_MEDIUM,
}

export const Hero36MediumRegular = {
  ...Hero36Medium,
  fontFamily: IMPORTANT_REGULAR,
}

/**
 *  Header
 *  */


export const Header28Bold = {
  fontSize: '1.75rem',
  lineHeight: '2rem',
  letterSpacing: 0,
  fontFamily: IMPORTANT_BOLD,
}

export const Header24LargeBold = {
  ...Header24Large,
  fontFamily: IMPORTANT_BOLD,
}

export const Header24LargeMedium = {
  ...Header24Large,
  fontFamily: IMPORTANT_MEDIUM,
}

export const Header24LargeRegular = {
  ...Header24Large,
  fontFamily: IMPORTANT_REGULAR,
}

export const Header21MediumBold = {
  ...Header21Medium,
  fontFamily: IMPORTANT_BOLD,
}

export const Header21MediumMedium = {
  ...Header21Medium,
  fontFamily: IMPORTANT_MEDIUM,
}

export const Header21MediumRegular = {
  ...Header24Large,
  fontFamily: IMPORTANT_REGULAR,
}

export const Header18SmallBold = {
  ...Header18Small,
  fontFamily: IMPORTANT_BOLD,
}

export const Header18SmallMedium = {
  ...Header18Small,
  fontFamily: IMPORTANT_MEDIUM,
}

export const Header18SmallRegular = {
  ...Header18Small,
  fontFamily: IMPORTANT_REGULAR,
}

export const Header18SmallSemiBold = {
  ...Header18Small,
  fontFamily: DEFAULT_SEMIBOLD,
}

export const Header16XSmallBold = {
  ...Header16XSmall,
  fontFamily: IMPORTANT_BOLD,
}

export const Header16XSmallMedium = {
  ...Header16XSmall,
  fontFamily: IMPORTANT_MEDIUM,
}

export const Header16XSmallRegular = {
  ...Header16XSmall,
  fontFamily: IMPORTANT_REGULAR,
}


export const Body16Semibold = {
  fontSize: '1rem',
  lineHeight: '1.375rem',
  fontFamily: DEFAULT_SEMIBOLD,
};

export const Body21Semibold = {
  fontSize: '1.3125rem',
  lineHeight: '1.75rem',
  fontFamily: DEFAULT_SEMIBOLD,
};

export const Grey_90 = "rgba(38, 38, 38, 1)";

export const GreyCool_10 = "#F2F4F8";
export const GreyCool_20 = "#DEE0E4";
export const GreyCool_60 = "#6b6f77";
export const GreyCool_70 = "#4D525C";
export const GreyCool_80 = "#343943";
export const GreyCool_100 = "#101622";
