import { styles as baseStyles } from "./base";
import { styles as imgStyles } from "./img";
import { styles as tableStyles } from "./table";

export const mergeStyles = (styles: Record<string, any>[]): object => {
  const mergedStyles: Record<string, any> = {};
  const mediaStyles: Record<string, any> = {};
  styles.forEach((style) => {
    for (const key in style) {
      if (style.hasOwnProperty(key)) {
        if (key.startsWith("@media")) {
          if (mediaStyles.hasOwnProperty(key)) {
            mediaStyles[key] = { ...mediaStyles[key], ...style[key] };
          } else mediaStyles[key] = style[key];
        } else {
          mergedStyles[key] = style[key];
        }
      }
    }
  });
  return { ...mergedStyles, ...mediaStyles };
};

export const globalStyles = {
  "@global": mergeStyles([baseStyles, imgStyles, tableStyles]),
};
