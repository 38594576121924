import { GtmPlayerSizes } from './gtmTypes';

const BREAKPOINTS = [
  {
    min: 320,
    max: 479,
    label: '320-479',
  },
  {
    min: 480,
    max: 767,
    label: '480-767',
  },
  {
    min: 768,
    max: 1135,
    label: '768-1135',
  },
  {
    min: 1136,
    max: Infinity,
    label: '1136+',
  },
];

export const getBreakpointLabel = (width?: number): undefined | GtmPlayerSizes => {
  if (width === undefined) {
    return undefined;
  }

  const result = BREAKPOINTS.find((bp) => {
    if (width >= bp.min && width <= bp.max) {
      return true;
    }
    return false;
  });

  if (result) {
    return result.label as GtmPlayerSizes;
  }

  return undefined;
};
