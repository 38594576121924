import React, { useContext } from 'react';

export class MissingProviderError extends Error {
  constructor(Context: React.Context<any>) {
    super(`${Context.displayName || 'Unnamed'} provider is missing in React tree`);
  }
}

export const useContextWithProvider = <A>(Context: React.Context<A | undefined>): A => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new MissingProviderError(Context);
  }
  return context;
};
