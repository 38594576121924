import debounce from 'lodash/debounce';
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useResponsiveContext } from './ResponsiveContext';
import { ResizeObserver } from './ResizeObserver';

export interface MediaQuery {
  maxWidth?: number;
  minWidth?: number;
  query?: string;
}

export const buildMediaQuery = ({
  maxWidth,
  minWidth,
  query,
  useScreen = true,
}: MediaQuery & {
  useScreen?: boolean;
}): string => {
  let mediaQuery = useScreen ? '@media screen' : '';
  if (typeof minWidth !== 'undefined') {
    mediaQuery += `${useScreen ? ' and ' : ''}(min-width: ${minWidth}px)`;
  }
  if (typeof maxWidth !== 'undefined') {
    mediaQuery += `${useScreen ? ' and ' : ''}(max-width: ${maxWidth}px)`;
  }
  if (typeof query !== 'undefined') {
    mediaQuery += `${mediaQuery.length > 0 ? ' and ' : ''}${query}`;
  }
  return mediaQuery;
};

export const useMediaQuery = (query: MediaQuery, ref?: RefObject<HTMLElement>): boolean => {
  const mediaQueryList = useRef<MediaQueryList | null>(null);
  const resizeObserver = useRef<ResizeObserver | null>(null);
  const { ref: contextRef, wasHydrated = false } = useResponsiveContext();

  const usedRef = !query.query ? ref || contextRef : undefined;

  const resizeHandler = useCallback(
    ({ width }: { width: number }) => {
      const minWidth = query.minWidth || 0;
      const maxWidth = query.maxWidth || Infinity;

      return width >= minWidth && width <= maxWidth;
    },
    [query.maxWidth, query.minWidth]
  );

  let defaultMatch = false;

  // obsah už byl zhydratován takže můžeme detekovat defaultní hodnoty pro martch
  // a nemusí nás zajímat rozdíl mezi ssr a klientem
  if (wasHydrated) {
    if (!usedRef) {
      mediaQueryList.current = window.matchMedia(buildMediaQuery(query).replace('@media ', ''));
      defaultMatch = mediaQueryList.current.matches;
    } else {
      defaultMatch = usedRef.current
        ? resizeHandler({ width: usedRef.current.getBoundingClientRect().width })
        : false;
    }
  }

  const [matches, setMatches] = useState(defaultMatch);

  useEffect(() => {
    // Pokud neni specifikovany referencni element, pouzivame klasicke document media query.
    // V opacnem pripade musime naslouchat na zmenu velikosti elementu

    // defaultní hodnotu nastavujeme v useeffectu, protože by nám jinak dělala problém při hydrataci
    if (!usedRef && typeof window !== 'undefined' && window.matchMedia) {
      mediaQueryList.current = window.matchMedia(buildMediaQuery(query).replace('@media ', ''));
      setMatches(!!mediaQueryList.current.matches);
    } else if (usedRef?.current) {
      setMatches(resizeHandler({ width: usedRef.current.getBoundingClientRect().width }));
    }
  }, [query, resizeHandler, usedRef]);

  useEffect(() => {
    let mounted = true;
    if (!usedRef) {
      if (!mediaQueryList.current) {
        return () => {
          mounted = false;
        };
      }

      const changeListener = (e: MediaQueryListEvent) => {
        if (mounted) {
          setMatches(e.matches);
        }
      };
      if (mediaQueryList.current.addEventListener) {
        mediaQueryList.current.addEventListener('change', changeListener);
      } else if (mediaQueryList.current.addListener) {
        mediaQueryList.current.addListener(changeListener);
      }

      return () => {
        mounted = false;
        if (mediaQueryList.current) {
          if (mediaQueryList.current.removeEventListener) {
            mediaQueryList.current.removeEventListener('change', changeListener);
          } else if (mediaQueryList.current.removeListener) {
            mediaQueryList.current.removeListener(changeListener);
          }
          mediaQueryList.current = null;
        }
      };
    }

    const element = usedRef.current;
    if (!element) {
      return () => {
        mounted = false;
      };
    }

    // throttle posílal do console errory
    const resizeObserverHandler = debounce((entries: ResizeObserverEntry[]) => {
      entries.forEach((entry) => {
        if (mounted) {
          setMatches(resizeHandler(entry.contentRect));
        }
      });
    }, 100);

    resizeObserver.current = new ResizeObserver(resizeObserverHandler);
    resizeObserver.current.observe(element);

    return () => {
      mounted = false;
      resizeObserver.current?.disconnect();
    };
  }, [resizeHandler, usedRef]);

  return matches;
};

// query se definuje mimo media query, aby měla stále stejnou referenci pro react

export const desktop1440 = 1440;
export const MIN1440 = `(min-width: ${desktop1440}px)`;
export const mediaMinDesktop1440 = `@media ${MIN1440}`;
const minDesktop1440Query = { minWidth: desktop1440 };
export const useMinDesktop1440 = () => useMediaQuery(minDesktop1440Query);

export const desktop1200 = 1200;
export const MIN1200 = `(min-width: ${desktop1200}px)`;
export const mediaMinDesktop1200 = `@media ${MIN1200}`;
const minDesktop1200Query = { minWidth: desktop1200 };
export const useMinDesktop1200 = () => useMediaQuery(minDesktop1200Query);

export const desktop1024 = 1024;
export const MIN1024 = `(min-width: ${desktop1024}px)`;
export const mediaMinDesktop1024 = `@media ${MIN1024}`;
const minDesktop1024Query = { minWidth: desktop1024 };
export const useMinDesktop1024 = () => useMediaQuery(minDesktop1024Query);

export const tablet768 = 768;
export const MIN768 = `(min-width: ${tablet768}px)`;
export const mediaMinTablet768 = `@media ${MIN768}`;
const minTablet768Query = { minWidth: tablet768 };
export const useMinTablet768 = () => useMediaQuery(minTablet768Query);

export const tablet640 = 640;
export const MIN640 = `(min-width: ${tablet640}px)`;
export const mediaMinTablet640 = `@media ${MIN640}`;
const minTablet640Query = { minWidth: tablet640 };
export const useMinTablet640 = () => useMediaQuery(minTablet640Query);

export const mobile480 = 480;
export const MIN480 = `(min-width: ${mobile480}px)`;
export const mediaMinMobile480 = `@media ${MIN480}`;
const minMobile480Query = { minWidth: mobile480 };
export const useMinMobile480 = () => useMediaQuery(minMobile480Query);

export const mobile428 = 428;
export const MIN428 = `(min-width: ${mobile428}px)`;
export const mediaMinMobile428 = `@media ${MIN428}`;
const minMobile428Query = { minWidth: mobile428 };
export const useMinMobile428 = () => useMediaQuery(minMobile428Query);

export const mobile360 = 360;
export const MIN360 = `(min-width: ${mobile360}px)`;
export const mediaMinMobile360 = `@media ${MIN360}`;
const minMobile360Query = { minWidth: mobile360 };
export const useMinMobile360 = () => useMediaQuery(minMobile360Query);
