import { AnalyticsSubscriber } from './subscribers';
import { AnalyticsEvents } from './events';

type PreAction = null | (() => void);

export class AnalyticsClient<AnalyticsContext extends Record<string, any> = Record<string, any>> {
  private analyticsContext: AnalyticsContext | null = null;

  private analyticsSubscribers: AnalyticsSubscriber[] = [];

  private onContextChange: (newContext: AnalyticsContext | null) => void = () => {};

  private preAction: PreAction = null;

  constructor(subscribers?: AnalyticsSubscriber[]) {
    this.addSubscribers(subscribers);
  }

  addSubscriber(subscriber: AnalyticsSubscriber): void {
    this.analyticsSubscribers.push(subscriber);
  }

  addSubscribers(subscribers?: AnalyticsSubscriber[]): void {
    if (subscribers) {
      subscribers.forEach((subscriber) => {
        this.analyticsSubscribers.push(subscriber);
      });
    }
  }

  destroy(): void {
    this.removeSubscriber();
  }

  getContext() {
    return this.analyticsContext as AnalyticsContext;
  }

  removeSubscriber(name?: string): boolean {
    if (!name) {
      this.analyticsSubscribers = [];
      return true;
    }

    const subscriberIndex = this.analyticsSubscribers.findIndex(
      (subscriber) => subscriber.name === name
    );

    if (subscriberIndex === -1) {
      return false;
    }

    this.analyticsSubscribers.splice(subscriberIndex, 1);

    return true;
  }

  setContext(context: AnalyticsContext | null): void {
    this.analyticsContext = context;
    this.onContextChange(context);
  }

  setOnContextChange(callback: (newContext: AnalyticsContext | null) => void): void {
    this.onContextChange = callback;
  }

  setPreAction(preAction: PreAction) {
    this.preAction = preAction;
  }

  setSubscribers(subscriber: AnalyticsSubscriber[]): void {
    this.analyticsSubscribers = subscriber;
  }

  trigger(event: Omit<AnalyticsEvents, 'context'>): void {
    if (this.preAction) {
      this.preAction();
    }

    // kazdeho subscribera nechame zpracovat eventu
    this.analyticsSubscribers.forEach((analyticsSubscriber) => {
      analyticsSubscriber.callback({ ...event, context: this.analyticsContext } as AnalyticsEvents);
    });
  }
}
