export interface Consent {
  analytics: boolean;
  consentInteraction: boolean;
  marketing: boolean;
  preferences: boolean;
}

declare global {
  interface Window {
    ctOnCookieConsentChanged?: (data: Consent) => void;
  }
}

export const setOnCookieConsentChanged = (callback: (data: Consent) => void) => {
  if (window.ctOnCookieConsentChanged) {
    const originalCallback = window.ctOnCookieConsentChanged;
    window.ctOnCookieConsentChanged = (...args) => {
      originalCallback(...args);
      callback(...args);
    };
    return;
  }

  window.ctOnCookieConsentChanged = callback;
};
